.key-info-section{
    background-color: $white;
    padding-top: 60px;
    position: relative;
    
    height: calc(100vh - 113px);
    overflow: hidden;
    
    @media screen and(max-width: 767px){
        padding-top: 20px;
        height: 100%;
    }
}

// .safari{
//     .key-info-section{
//         .go-down-anchor{
//             // bottom: 30px;
//         }
//     }
// }

.key-info__charts-container{
    padding-bottom: 30px;
    text-align: center;
    h1{
        font-size: 24px;
        text-transform: uppercase;
        color: $dark-primary;
        font-weight: 500;
        margin-bottom: 20px;
        @media screen and(min-width: 768px) and (max-width: 991px){
            font-size: 21px;
        }
        @media screen and(max-width: 767px){
            font-size: 18px;
        }
    }
}
.key-info__charts-1, .key-info__charts-2{
   text-align: center;
   position: relative;
   height: 200px;
   margin-top: 40px;
   
   @media screen and(max-width: 767px){
    width: 260px;
    margin: 15px auto;

   }
   @media screen and(min-width: 768px) and (max-width: 991px){
    margin-left: 45px;
    margin-right: 45px;
   }
   @media screen and(min-width: 992px) and (max-width: 1199px){
    margin-left: 100px;
    margin-right: 100px;
   }
   @media screen and(min-width: 1200px){
       margin-left: 150px;
       margin-right: 150px;
   }
   
   div{
       display: inline-block;
       border-radius: 100%;
       background-color: $white;
   }
}
.key-info__chart-1-1, .key-info__chart-2-1{
    width: 110px;
    height: 110px;
    // background-color: $pink;
    position: absolute;
    z-index: 200;
    left: 0;
}
.key-info__chart-1-2, .key-info__chart-2-2{
    width: 180px;
    height: 180px;
    // background-color: $dark-primary;
    position: absolute;
    right: 0;
    top: 20px;
}

.key-info__graphics-wrapper{
    position: relative;
    overflow: hidden;
    height: 500px;
    img, div{
        position: absolute;
    }
}
#key-info__graphics-1{
    width: 140px;
    height: 240px;
    padding-top: 100px;
    bottom: 300px;
    right: 15vw;
    @media screen and(min-width: 992px) and (max-width: 1199px){
        right: 5vw;
    }
    @media screen and(min-width: 768px) and (max-width: 991px){
        right: 3vw;
        bottom: 350px;
    }
}
#key-info__graphics-2{
    width: calc(100% + 40px);
    height: 500px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: -55px;
    left: 20px;
    @media screen and(min-width: 768px) and (max-width: 991px){
        left: 0;
    }
}
#key-info__graphics-3{
    width: 120px;
    height: auto;
    bottom: 100px;
    left: 15vw;
    @media screen and(min-width: 992px) and (max-width: 1199px){
        left: 5vw;
    }
    @media screen and(min-width: 768px) and (max-width: 991px){
        left: auto;
        right: 25vw;
    }
}

@media screen and(max-width: 767px){
    .key-info__graphics-wrapper{
        height: 220px;
        background-image: url('../../assets/images/kluczowe_dane.png');
        background-position-x: 35%;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: 767px auto;
        div, img{
            display: none;
        }
    }
}