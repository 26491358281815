.header--media {
  background: #4f5c64;
  border-bottom: 5px solid $teal-light;
}

.media-section {
  min-height: calc(100vh - 200px);
  padding-top: 80px;
  position: relative;
  background-color: #ffffff;

  @media screen and (min-width: 768px) {
    min-height: calc(100vh - 70px);
    padding-top: calc(64px + 60px);
    background-position: 50% 100%;
    background-size: 100%;
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background-image: url('/assets/images/media.png');
    background-repeat: no-repeat;
    background-position: 35% 100%;
  }

  &__square {
    width: 120px;
    height: 220px;
    position: absolute;
    display: none;
    bottom: 100px;
    left: 15%;
    z-index: 2;
    border: 10px solid #B8DCE2;

    @media screen and (min-width: 768px) {
      display: block;
    }

    &::before {
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      top: -60px;
      left: -60px;
      background: #B8DCE2;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      left: -80px;
      background: #B8DCE2;
    }
  }

  &__square-2 {
    width: 100px;
    height: 220px;
    display: none;
    position: absolute;
    bottom: 50px;
    right: 6%;
    z-index: 0;
    background: #B8DCE2;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  &__container {
    padding: 0 !important;
    position: relative;
    z-index: 10;

    @media screen and (min-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__list-container {
    padding-bottom: 30px;
    margin-bottom: 30px;
    background: rgba(#ffffff, 0.9);
  }

  &__list {
    min-height: 130px;
    margin-bottom: 30px;
    border-top: 1px solid lightgrey;

    @media screen and (min-height: 600px) and (min-width: 769px) {
      min-height: 395px;
    }

    @media screen and (max-width: 768px) {
      min-height: 195px;
    }
  }

  &__item {
    padding: 10px 15px 20px;
    border-bottom: 1px solid lightgrey;

    @media screen and (min-width: 768px) {
      padding: 10px 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__item-date {
    color: #a8b0b2;
    margin-bottom: 5px;
    font-size: 12px;
  }

  &__item-title {
    margin-bottom: 0;
    padding-right: 15px;
    font-size: 16px;
    color: #4f5c64;
  }

  &__button {
    margin-top: 5px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
  }

  .button--bordered {
    padding: 4px 24px;
  }

  &__pagination-container {
    display: flex;
    justify-content: center;
  }

  &__pagination {
    width: 124px;
    height: 30px;
    position: relative;

    button {
      width: 30px;
      height: 30px;
      color: #4f5c64;
      border: none;
      background: transparent;
    }

    #button-0 {
      position: absolute;
      left: 0;
    }
    #button-1 {
      position: absolute;
      left: 31px;
    }
    #button-2 {
      position: absolute;
      left: 62px;
    }
    #button-3 {
      position: absolute;
      left: 93px;
    }
    #button-4 {
      position: absolute;
      left: 124px;
    }
  }

  &__pagination-button--active {
    border: 2px solid #4f5c64 !important;
  }
}

.media-footer {
  padding: 8px;

  span {
    @media screen and (min-width: 768px) {
      margin: 0 3px;
    }
  }

  a {
    color: #333;

    &:hover {
      text-decoration: none;
    }
  }

  &--phone {
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  &__content {

    &--mobile {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
