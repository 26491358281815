footer{
    padding: 10px 0;
    background-color: $dark-primary;
    font-size: 12px;
    span{
        color: $white;
        a{
            color: $white;
            &:link, &:active, &:visited, &:hover{
                color: $white;
            }
        }
    }
    .footer__col-right{
        text-align: right;
    }
    @media screen and(max-width: 767px){
        .footer__col-left, .footer__col-right{
            text-align: center;
            padding: 5px 0;
        }
    }
}

.cookie-law-info__wrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1200;
    background-color: $dark-primary;
    color: $white;
    padding: 20px 0;
    transform: translateY(100%);
    transition: transform 550ms cubic-bezier(0.245, 0.410, 0.000, 1);

    &.visible{
        transform: translateY(0);
    }
    &.accepted{
        display: none;
    }

    img{
        float: left;
        margin-right: 15px;
        width: 54px;
        height: 54px;
    }
    p{
        padding: 10px 0;
        margin: 0;
    }
    a{
        color: $teal-primary;
        text-decoration: underline;
        &:link, &:hover, &:active, &:visited{
            color: $teal-primary;
        }
        &:hover{
            text-decoration: none;
        }
    }
    .cookies-button{
        float: right;
        margin: 12px 0;
    }

    @media screen and(max-width: 767px){
        padding: 12px 0;
        font-size: 12px;
        text-align: center;
        img{
            display: none;
            p{
                padding: 0;
            }
        }
        .cookies-button{
            float: none;
            margin: 10px 0 0 0;
        }
    }
}