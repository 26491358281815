.map-section{
    position: relative;
    height: 600px;
    @media screen and(max-width: 767px){
        height: auto;
    }
}
.map{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $dark-primary;
    height: 600px;
    @media screen and(max-width: 767px){
        position: static;
        height: 40vh;
        @media screen and(orientation:landscape){
            height: calc(100vh - 100px);
        }
    }
}
.map__info-container{
    position: relative;
    z-index: 500;
    padding: 0 40px;
    top: 50px;
    pointer-events: none;
    @media screen and(max-width: 767px){
        position: static;
        padding: 0 15px;
    }
}
.map__info{
    padding: 25px;
    pointer-events: auto;
    background-color: $white;
    width: 320px;
    max-height: 480px;
    color: $dark-primary;
    font-size: 16px;
    @media screen and(max-width: 767px){
        position: static;
        padding: 25px 0;
        width: 100%;
        max-height: auto;
    }
}
.map__info-opening-hours{
    margin: 32px 0;
    h1{
        font-size: 21px;
        text-transform: uppercase;
    }
}
