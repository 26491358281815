.about-section{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0;
    min-height: calc(100vh - 113px);
    position: relative;
    overflow: hidden;

    @media screen and(max-width: 767px){
        min-height: calc(100vh - 100px);
    }

    .hero-section__content{
        padding-top: 100px;
        .row{
            z-index: 100;
            padding-bottom: 50px;
        }
        h1{
            font-size: 36px;
            margin-bottom: 24px;
        }
        p, ul li{
            font-size: 20px;
            letter-spacing: 0.3px;
        }
        ul{
            li:before {
                content: '■';
                color: $teal-light;
                font-size: 18px;
                margin-right: 10px;
                position: relative;
                top: -2px;
              }
            li{
                margin-bottom: 12px;
            }
        }
        ol{
            font-size: 20px;
            list-style: decimal;
            list-style-position: inside;
            li{
                margin-bottom: 12px;
            }
        }

        @media screen and(max-width: 767px){
            padding-top: 5vw;
            h1{
                font-size: 24px;
                margin-bottom: 16px;
            }
            p, ul li{
                font-size: 16px;
            }
        }
        @media screen and(min-width: 768px) and (max-width: 991px){
            h1{
                font-size: 28px;
                margin-bottom: 12px;
            }
        }
        @media screen and(min-width: 992px) and (max-width: 1199px){
            h1{
                font-size: 30px;
            }
        }
    }
    .container{
        position: relative;
    }
    .about__squares{
        opacity: 0.5;
        position: absolute;
        top: calc(50% - 150px);
        left: 0;
        @media screen and(max-width: 991px){
            display: none;
        }
        img{
            position: absolute;
        }
        #square_1{
            width: 36px;
            height: 36px;
            top: 14px;
            left: 7px;
        }
        #square_2{
            width: 105px;
            height: 105px;
            top: -96px;
            left: 44px;
        }
        #square_3{
            width: 190px;
            height: 470px;
            top: 10px;
            left: 150px;
        }
        #square_4{
            width: 85px;
            height: 85px;
            top: 476px;
            left: 337px;
        }
    }
}
