.strategy-section {
  background-color: $white;
  color: $dark-primary;
  min-height: calc(100vh - 113px);
  position: relative;
  overflow: hidden;

  padding-bottom: 160px;

  @media screen and(max-width: 767px) {
    min-height: calc(100vh - 100px);
    padding-bottom: 115px;
  }
}
.strategy__mobile-heading {
  margin-top: 30px;
  text-align: center;
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  p {
    font-size: 18px;
    letter-spacing: 0.3px;
  }
  div{
    ul{
      list-style: disc;
      font-size: 18px;
      list-style-position: inside;
    }
    ol{
      list-style: decimal;
      font-size: 18px;
      list-style-position: inside;
    }
  }
}
.strategy__heading {
  margin: 30px 0 10px 0;
  text-align: center;
  h1 {
    font-size: 36px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  p {
    font-size: 20px;
    letter-spacing: 0.3px;
  }
  h1,
  div {
    transition-property: all;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: translateY(30px);

    ul{
      list-style: disc;
      font-size: 20px;
      list-style-position: inside;
    }
    ol{
      list-style: decimal;
      font-size: 20px;
      list-style-position: inside;
    }
  }

  @media screen and(max-width: 767px) {
    margin-top: 35px;
    h1 {
      font-size: 24px;
      margin-bottom: 16px;
    }
    p, ul, ol {
      font-size: 16px;
    }
  }
  @media screen and(min-width: 768px) and (max-width: 991px) {
    h1 {
      font-size: 28px;
      margin-bottom: 12px;
    }
  }
  @media screen and(min-width: 992px) and (max-width: 1199px) {
    h1 {
      font-size: 30px;
    }
  }
}

#strategy__mobile-accordion {
  .strategy__mobile-section {
    box-shadow: none;
    .panel-heading {
      padding-left: 0;
      padding-right: 0;
    }
    .panel-title {
      a {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.3px;
        padding: 0 25px;
        height: 280px;
        display: table;
        width: 100%;
        span{
          display: table-cell;
          vertical-align: middle;
        }
        &:active,
        &:hover,
        &:link,
        &:visited {
          color: $white;
          text-decoration: none;
        }
        transition: all 150ms ease-out;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}

.strategy__slider {
  *:focus {
    outline: none !important;
  }
}
[class^="strategy__slider-arrow"] {
  position: absolute;
  top: calc(50% - 19px);
  cursor: pointer;
  transition: all 150ms ease-out;
}
.strategy__slider-arrow-left {
  left: -15px;
  &:hover {
    left: -20px;
  }
  @media screen and(max-width: 1023px) {
    left: 0;
    &:hover {
      left: 0;
    }
  }
}
.strategy__slider-arrow-right {
  right: -15px;
  &:hover {
    right: -20px;
  }
  @media screen and(max-width: 1023px) {
    right: 0;
    &:hover {
      right: 0;
    }
  }
}
.strategy__slider-item {
  padding: 6vh 0;
  position: relative;
}
.strategy__slider-item--first {
  padding: 0;
  text-align: center;
}
.strategy__anchors-wrapper {
  padding: 40px 30px;
}
.strategy__slider-anchor--primary {
  display: flex;
  align-items: center;
  float: left;
  margin: 0 15px;
  width: calc(33% - 30px);
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 0 65px;
  height: 388px;
  color: $white;
  background-size: cover;
  background-repeat: no-repeat;
  transition-property: opacity, background-image, box-shadow;
  transition-duration: 800ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  vertical-align: top;
  cursor: pointer;
  &:active,
  &:hover,
  &:link,
  &:visited,
  &:focus {
    color: $white;
    text-decoration: none;
  }
  &:hover {
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.4);
  }
  span{
    display: inline-block;
    width: 100%;
  }

  @media screen and(min-width: 768px) and (max-width: 991px) {
    height: 328px;
    padding: 0 20px;
    font-size: 18px;
    width: calc(33% - 40px);
  }
  @media screen and(min-width: 992px) and (max-width: 1199px) {
    height: 288px;
    padding: 0 30px;
  }
}

.strategy__slider-item-text {
  h1 {
    font-size: 36px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  p {
    font-size: 16px;
    letter-spacing: 0.2px;
  }
  h1,
  div {
    transition-property: all;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: translateY(30px);
  }
  padding-left: 30px;

  ul{
    list-style: disc inside;
    padding-left: 30px;
    font-size: 16px;
  }
  ol{
    list-style: decimal;
    padding-left: 30px;
    font-size: 16px;
  }
}
.strategy__slider-item-image {
  img {
    transition-property: all;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: translateY(30px);
  }
  padding-right: 30px;

  [class^="strategy__squares"] {
    position: absolute;
  }
  .strategy__squares-1 {
    width: 100px;
    top: 15%;
    left: 16%;
  }
  .strategy__squares-2 {
    width: 130px;
    z-index: 100;
    left: 19%;
    bottom: -2%;
  }

  @media screen and(min-width: 768px) and (max-width: 991px){
    [class^="strategy__squares"] {
      z-index: 100;
    }
    .strategy__squares-1{
      width: 70px;
      top: 5%;
      left: -2%;
    }
    .strategy__squares-2{
      width: 100px;
      left: 10%;
      bottom: -5%;
    }
  }
  @media screen and(min-width: 992px) and (max-width: 1199px){
    [class^="strategy__squares"] {
      z-index: 100;
    }
    .strategy__squares-1{
      width: 90px;
      top: 8%;
      left: 5%;
    }
  }
}

.strategy__slider-item-text,
.strategy__slider-item-image {
  height: 500px;
  min-height: 540px;
  @include flexbox;
  @include align-items(center);

  @media screen and(max-width: 991px) {
    min-height: 420px;
  }
}

.strategy__slider-item__next-anchor{
  position: absolute;
  min-width: 150px;
  padding: 20px 10px 20px 16px;
  text-align: right;
  display: block;
  top: calc(50% - 28px);
  right: 10px;
  z-index: 200;
  background-color: $white;
  text-transform: uppercase;
  color: $dark-primary;
  transition-property: all;
  transition-duration: 800ms;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateX(-30px);
  cursor: pointer;
  &:active,
  &:focus,
  &:link,
  &:visited,
  &:hover{
    color: $dark-primary;
    text-decoration: none;
  }
}

.strategy__slider-item--revealed {
  .strategy__heading {
    h1,
    div {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .strategy__slider-anchor--primary {
    opacity: 1;
  }
  .strategy__slider-item-text {
    h1,
    div {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .strategy__slider-item-image {
    img {
      opacity: 1;
      transform: translateY(0);
      margin-left: auto;
    }
  }
  .strategy__slider-item__next-anchor{
    opacity: 1;
    transform: translateX(0);
  }
}
