.header {
  height: 63px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  @include transition(all 150ms ease-out);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.38) 15%,
    rgba(0, 0, 0, 0.19) 52%,
    rgba(0, 0, 0, 0.09) 68%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.38) 15%,
    rgba(0, 0, 0, 0.19) 52%,
    rgba(0, 0, 0, 0.09) 68%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.45) 0%,
    rgba(0, 0, 0, 0.38) 15%,
    rgba(0, 0, 0, 0.19) 52%,
    rgba(0, 0, 0, 0.09) 68%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#73000000",
      endColorstr="#00000000",
      GradientType=0
    ); /* IE6-9 */

  @media screen and(max-width: 767px) {
    height: 50px;
  }
}
.header--solid,
.header--solid-permanent {
  background: none;
  .header__wrapper {
    background-color: $dark-primary;
  }
}
.header--submenu {
  height: 113px;
  @media screen and(max-width: 767px) {
    height: 100px;
  }
  &:not(.automatic-scrolling) {
    .header__submenu-wrapper {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &.automatic-scrolling {
    .mobile__submenu-list {
      display: none !important;
    }
  }
}

.header__wrapper {
  position: relative;
  z-index: 3200;
  transition: background-color 150ms linear;
}

.header__column {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
}

.header__logo {
  position: relative;
  z-index: 5000;
  float: left;
  padding: 8px 0 10px 0;
  img {
    height: 100%;
    width: 260px;
    @include transition(all 150ms ease-out);
  }
  @media screen and(max-width: 767px) {
    padding: 0;
    height: 50px;
    img {
      margin-top: 4px;
      height: 40px;
      width: auto;
    }
  }
  @media screen and(max-width: 424px) {
    img {
      margin-top: 13px;
      height: 24px;
    }
  }
}

.header__menu--toggle {
  display: none;

  @media screen and(max-width: 767px) {
    display: block;
    box-sizing: border-box;
    position: relative;
    z-index: 5000;
    top: 3px;
    right: 8px;

    span {
      display: block;
      background: $white;
      border-radius: 4px;
      transition: 0.25s ease-in-out;
    }
    & {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: relative;
      #hamburger {
        position: absolute;
        height: 100%;
        width: 100%;
        span {
          width: 32px;
          height: 3px;
          position: relative;
          top: 0px;
          left: 4px;
          margin: 6px 0;
          &:nth-child(1) {
            transition-delay: 0.5s;
          }
          &:nth-child(2) {
            transition-delay: 0.625s;
          }
          &:nth-child(3) {
            transition-delay: 0.75s;
          }
        }
      }
      #cross {
        position: absolute;
        top: -2px;
        height: 100%;
        width: 100%;
        transform: rotate(45deg);
        span {
          &:nth-child(1) {
            height: 0%;
            width: 3px;
            position: absolute;
            top: 2px;
            left: 18px;
            transition-delay: 0s;
          }
          &:nth-child(2) {
            width: 0%;
            height: 3px;
            position: absolute;
            left: 10%;
            top: 16px;
            transition-delay: 0.25s;
          }
        }
      }
    }

    &.open {
      #hamburger {
        span {
          width: 0%;
          &:nth-child(1) {
            transition-delay: 0s;
          }
          &:nth-child(2) {
            transition-delay: 0.125s;
          }
          &:nth-child(3) {
            transition-delay: 0.25s;
          }
        }
      }
      #cross {
        span {
          &:nth-child(1) {
            height: 80%;
            transition-delay: 0.625s;
          }
        }
        span {
          &:nth-child(2) {
            width: 80%;
            transition-delay: 0.375s;
          }
        }
      }
    }
  }
}

.header__menu-wrapper {
  @media screen and(max-width: 767px) {
    position: absolute;
    z-index: 4500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-top: 70px;

    nav {
      height: calc(100vh - 70px);
      overflow-y: auto;
    }

    .header__menu__list {
      display: block;
      .header__menu__list-item {
        display: block;
        text-align: center;
        margin: 15px 0;
        a {
          font-size: 18px;
          font-weight: 600;
        }
        .mobile__submenu-list {
          .mobile__submenu-list-item {
            margin: 8px 0;
            a {
              font-weight: 400;
              font-size: 12px;
              padding: 4px 6px;
              border-bottom: 2px transparent solid;
              &:hover:after,
              &:hover:before,
              &:not(.automatic-scrolling).active:after,
              &:not(.automatic-scrolling).active:before {
                border-width: 0px;
              }
            }
          }
        }
        a.active + .mobile__submenu-list {
          .mobile__submenu-list-item {
            a.active {
              border-bottom-color: $white;
            }
          }
        }
      }
    }
    .header__language-select {
      margin: 40px 0;
      display: block;
      text-align: center;
      .header__menu__list-item {
        display: inline-block;
        a {
          font-size: 14px;
        }
      }
    }
  }
}
.mobile__submenu-list {
  @media screen and(min-width: 768px) {
    display: none !important;
  }
}
.header__menu {
  text-align: right;
}
.header__menu__list {
  display: inline-block;
  padding-left: 0;
  margin: 5px 0;
  list-style-type: none;
}
.header__language-select {
  display: inline-block;
  margin-left: 50px;
  .header__menu__list-item {
    margin-left: 0;
  }
  @media screen and(min-width: 768px) and (max-width: 991px) {
    margin-left: 20px;
  }
}

.header__menu__list-item {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 20px;
  @include transition(all 150ms ease-out);

  @media screen and(min-width: 768px) and (max-width: 991px) {
    margin-left: 10px;
  }

  a {
    padding: 9px 13px;
    font-size: 15px;
    display: inline-block;
    position: relative;
    &:active,
    &:link,
    &:focus,
    &:visited {
      color: $white;
      text-decoration: none;
    }
    &.active {
      font-weight: 600;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 0%;
      height: 0%;
      z-index: 1;
      border-top: 3px solid white;
      border-right: 3px solid white;
      border-width: 0px;
    }
    &:before {
      content: " ";
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 0%;
      height: 0%;
      z-index: 1;
      border-bottom: 3px solid white;
      border-left: 3px solid white;
      border-width: 0px;
    }
    &:hover:after,
    &:hover:before,
    &:not(.automatic-scrolling).active:after,
    &:not(.automatic-scrolling).active:before {
      border-width: 2px;
      animation: aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
      animation-delay: 0s, 0.25s, 0.5s;
    }
    @include transition(all 150ms ease-out);

    @media screen and(min-width: 768px) and (max-width: 991px) {
      font-size: 12px;
      padding: 7px 9px;
    }
    @media (hover: none) {
      &:hover:after,
      &:hover:before {
        border-width: 0;
      }
    }
  }
}

.header__submenu-wrapper {
  opacity: 0;
  background-color: $teal-light;
  position: relative;
  z-index: 3000;

  transform: translateY(-100%);
  transition: all 200ms ease-out;

  @media screen and(max-width: 767px) {
    .submenu-wrapper__gradient {
      opacity: 1;
      z-index: 3200;
    }
  }
}
.submenu-wrapper__gradient {
  opacity: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+65,dbedf0+100&0+65,0.85+100 */
  background: -moz-linear-gradient(
    left,
    rgba(255,255,255, 0) 65%,
    rgba(219, 237, 240, 0.85) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255,255,255, 0) 65%,
    rgba(219, 237, 240, 0.85) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255,255,255, 0) 65%,
    rgba(219, 237, 240, 0.85) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#00000000",
      endColorstr="#d9dbedf0",
      GradientType=1
    ); /* IE6-9  */
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.header__submenu-column {
  padding: 8px 0 9px 0;
  @media screen and(max-width: 767px) {
    white-space: nowrap;
    overflow-x: auto;
  }
}

.header__submenu {
  text-align: center;

  .header__menu__list {
    margin: 0;
  }
  @media screen and(max-width: 767px) {
    text-align: left;
  }
}

.header__submenu-select-wrapper {
  width: 220px;
  float: left;
  margin-left: 40px;
  @media screen and(max-width: 991px) {
    display: none;
  }
  @media screen and(min-width: 992px) and(max-width: 1023px) {
    margin-left: 10px;
  }
}
.btn-group.bootstrap-select.header__submenu-select {
  .dropdown-toggle {
    color: $dark-primary;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: 1px $dark-primary solid;
    padding-top: 6px;
    padding-bottom: 6px;
    &:focus {
      color: $dark-primary;
      border-bottom-color: $dark-primary;
    }
    &:hover {
      color: $dark-primary;
      border-bottom-color: $dark-primary;
    }
    .bs-caret {
      .caret {
        background-image: url("../../assets/images/arrow_down_dark.svg");
      }
    }
  }
  &.open {
    .dropdown-toggle {
      padding-top: 6px;
      padding-bottom: 6px;
      .bs-caret {
        .caret {
          top: 1px;
        }
      }
    }
  }
}

.header__submenu-list--right {
  @media screen and(min-width: 992px) {
    float: right;
  }
  @media screen and(min-width: 992px) and (max-width: 1023px) {
    margin-right: 0;
  }
}

.header__submenu-list-item {
  a {
    font-size: 14px;
    padding: 7px 10px;
    &:active,
    &:link,
    &:focus,
    &:visited {
      color: $dark-primary;
    }
    &:after {
      border-top: 3px solid $dark-primary;
      border-right: 3px solid $dark-primary;
      border-width: 0;
    }
    &:before {
      border-bottom: 3px solid $dark-primary;
      border-left: 3px solid $dark-primary;
      border-width: 0;
    }
  }
  @media screen and(max-width: 767px) {
    &:last-child {
      margin-right: 40vw;
    }
  }
}

//Mobile menu overlay
@media screen and(max-width: 767px) {
  .header__mobile-overlay {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0s 0.5s;
    background-color: $dark-primary;
  }

  .header__mobile-overlay.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
  }

  .header__mobile-overlay nav {
    perspective: 1200px;
  }

  .header__mobile-overlay nav ul {
    opacity: 0.4;
    transform: translateY(-25%) rotateX(35deg);
    transition: transform 0.5s, opacity 0.5s, height 0.3s;
  }

  .header__mobile-overlay.open nav ul {
    opacity: 1;
    transform: rotateX(0deg);
  }

  .header__mobile-overlay.closed nav ul {
    transform: translateY(25%) rotateX(-35deg);
  }
}

//Nav buttons hover/active animations
@keyframes aW_sH {
  from {
    height: 100%;
    width: 0%;
  }
  to {
    height: 100%;
    width: 100%;
  }
}
@keyframes sW_aH {
  from {
    width: 100%;
    height: 0%;
  }
  to {
    width: 100%;
    height: 100%;
  }
}
@keyframes sW_sH {
  from {
    width: 100%;
    height: 100%;
  }
  to {
    width: 100%;
    height: 100%;
  }
}
@keyframes nW_nH {
  from,
  to {
    border-width: 0px;
  }
}
@keyframes nW_aH {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
@keyframes nW_sH {
  from,
  to {
    height: 100%;
  }
}
@keyframes sW_nH {
  from,
  to {
    width: 100%;
  }
}
@keyframes aW_nH {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
