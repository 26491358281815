body{
  background-color: $teal-light;
  display: block !important;
}
::selection {
  background: $teal-light;
}
.no-scroll {
  overflow: hidden;
}
.no-side-padding-xs {
  @media screen and(max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.safari{
  .go-down-anchor{
    &:hover{
      transform: none !important;
      box-shadow: none;
      background-color: rgba($pink, 1) !important;
    }
  }
  .go-down-anchor--teal{
    &:hover{
      background-color: rgba($teal-primary, 1) !important;
    }
  }
}

.go-down-anchor {
  color: $white;
  position: absolute;
  bottom: 0;
  left: calc(50% - 37.5px);
  margin: 0 auto;
  width: 75px;
  height: 100px;
  background-color: rgba($pink, 0.5);
  overflow: visible;
  will-change: transform;
  transition: all 250ms ease-out;
  transform-origin: 50% 100%;
  z-index: 1200;

  @media screen and(min-width: 768px) {
    width: 105px;
    height: 160px;
    left: calc(50% - 52.5px);

    &:hover {
      background-color: rgba($pink, 0.6);
      transform: perspective(600px) scale3d(1,1,1) rotateX(-14deg);
      box-shadow: 0px -10px 15px 1px rgba(0, 0, 0, 0.1);
    }
  }

  a {
    width: 200%;
    padding: 25px 0;
    display: block;
    text-align: center;
    margin-left: -50%;
    height: 100px;
    white-space: nowrap;
    overflow: visible;
    text-transform: uppercase;

    &:active,
    &:hover,
    &:link,
    &:visited {
      color: $white;
      text-decoration: none;
    }

    @media screen and(min-width: 768px) {
      height: 160px;
      padding: 55px 0;
      font-size: 16px;
    }
  }
  .go-down-anchor__icon-wrapper {
    margin-top: 15px;
    animation: bounce 2s infinite;
  }

  &.go-down-anchor--teal {
    background-color: $teal-light;
    a {
      &:active,
      &:hover,
      &:link,
      &:visited {
        color: $dark-primary;
      }
    }
    &:hover{
      background-color: $teal-light;
    }
  }
  &.go-down-anchor--semi-transparent-sm{
    @media screen and(min-width: 768px){
      opacity: 0.75;
    }
  }
}
.section--go-down {
  .hero-section__content {
    padding-bottom: 160px;

    @media screen and(max-width: 767px) {
      padding-bottom: 115px;
    }
  }
}
.transition-delay-100 {
  transition-delay: 100ms;
}
.transition-delay-200 {
  transition-delay: 200ms;
}
.transition-delay-300 {
  transition-delay: 300ms;
}
.transition-delay-500 {
  transition-delay: 500ms;
}
.transition-delay-700 {
  transition-delay: 700ms;
}

.background-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0;
}

[class*=slider__arrow--]{
  display: block;
  position: absolute;
  background-color: rgba($pink, 0.5);
  padding: 24px 2px 22px 2px;
  z-index: 200;
  transition: all 150ms ease-in;
  top: calc(50% - 36px);
  cursor: pointer;
  &:hover{
      background-color: $pink;
  }
}
ul.slider-dots{
  text-align: center;
  li{
    display: inline-block;
    button{
      font-size: 0;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: $white;
      margin: 5px;
      border: none;
      transition: background-color 150ms ease-out;
      &:focus{
        outline: none;
      }
      @media screen and(max-width: 767px){
        border: 2px $teal-primary solid;
        width: 17px;
        height: 17px;

        // Hack for targetting IE10+
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          width: 19px;
          height: 19px;
          border: none;
          box-shadow: inset 0 0 0 2px $teal-primary;
        }
       
      }
    }
    &.slick-active{
      button{
        width: 17px;
        height: 17px;
        background-color: $pink;
        border: 2px $white solid;
        @media screen and(max-width: 767px){
          width: 19px;
          height: 19px;
        }

        // Hack for targetting IE10+
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          width: 19px;
          height: 19px;
          border: none;
          box-shadow: inset 0 0 0 2px $white;
        }
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-7px);
  }
  60% {
    transform: translateY(-3px);
  }
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
