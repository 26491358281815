.history-section{
    padding: 20px 0 160px;
    background-color: $white;
    min-height: calc(100vh - 113px);
    position: relative;
    overflow: hidden;

    @media screen and(max-width: 767px){
        min-height: calc(100vh - 100px);
        padding: 10px 0 115px;
    }
}
.history-section__heading{
    font-size: 32px;
    font-weight: 600;
    color: $dark-primary;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
    @media screen and(max-width: 767px){
        font-size: 24px;
    }
}

.history__mobile-img-wrapper{
    height: calc(100vh - 250px);
    padding-bottom: 15px;
    overflow-x: auto;
    img{
        width: auto;
        height: calc(100vh - 265px);
    }
    @media screen and(orientation: landscape){
        height: calc(100vh + 15px);
        img{
            height: 100vh;
        }
    }
}
.history-video-wrapper{
    width: 100%;
    text-align: center;
}
.history-video{
    margin: 5px auto;
    background-color: $white;
}
video{
    background-color: $white;
}