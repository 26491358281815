.hero-section{
    @include flexbox;
    @include align-items(center);
    color: $white;
    height: 100%;
    width: 100%;
    position: relative;
}

.hero-section__content{
    text-align: center;
    width: 100%;

    h1{
        font-size: 64px;
        margin-bottom: 42px;
        text-transform: uppercase;
    }
    p{
        font-size: 21px;
        letter-spacing: 0.3px;
    }

    @media screen and(max-width: 767px){
        padding-top: 60px;
        h1{
            font-size: 32px;
            margin-bottom: 18px;
        }
        p{
            font-size: 16px;
        }
    }
    @media screen and(min-width: 768px) and (max-width: 991px){
        h1{
            font-size: 48px;
            margin-bottom: 24px;
        }
    }
    @media screen and(min-width: 992px) and (max-width: 1199px){
        h1{
            font-size: 52px;
        }
    }

}