.single-mall__key-info-section{
    color: $dark-primary;
    padding-bottom: 260px;
    padding-top: 60px;
    position: relative;
    min-height: calc(100vh - 113px);

    @media screen and(max-width: 767px){
        padding-bottom: 115px;
        min-height: calc(100vh - 100px);
    }
    h1{
        font-size: 34px;
        text-transform: uppercase;
        margin: 36px 0 24px 0;

        @media screen and(max-width: 767px){
            font-size: 26px;
            margin-bottom: 18px;
        }
    }
    .key-info__table{
        font-size: 20px;
        padding-bottom: 120px;
        width: 100%;
        @media screen and(max-width: 767px){
            font-size: 16px;
        }
        tbody{
            tr{
                &:not(:last-child):not(:nth-last-child(2)){
                    border-bottom: 1px $teal-primary solid;
                }
                td{
                    padding: 12px 0;
                }
                td:last-child:not([colspan]){
                    font-weight: 600;
                    text-align: right;
                    white-space: nowrap;
                }
            }
        }
    }
}
.shop-logos{
 margin-left: -10px;
}
.shop-logos__item{
    display: inline-block;
    width: 120px;
    height: 74px;
    float: left;
    padding: 12px;
    margin: 10px;
    border: 1px #999 solid;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    img{
        margin: 0 auto;
        min-width: 1px;
        max-width: 100%;
        max-height: 100%;
    }
}
.single-mall__key-info-graphics{
    position: relative;
    margin-top: 50px;
    img{
        position: absolute;
    }
}
#single__mall-graphic-1{
    top: 160px;
    right: 0;
    width: 250px;
    height: 250px;
    transform: scaleX(-1);
}
#single__mall-graphic-2{
    top: 270px;
    left: 90px;
    width: 140px;
    height: 420px;
}
#single__mall-graphic-3{
    top: 430px;
    left: 280px;
    width: 80px;
    height: 80px;
}
#single__mall-graphic-4{
    width: 500px;
    z-index: 500;
}
#single__mall-graphic-5{
    top: 360px;
    max-width: 200px;
    max-height: 200px;
    z-index: 100;
}

@media screen and(max-width: 1199px){
    #single__mall-graphic-1{
        top: 160px;
        width: 180px;
        height: 180px;
    }
    #single__mall-graphic-2{
        top: 130px;
        width: 130px;
    }
    #single__mall-graphic-3{
        top: 360px;
        left: 260px;
    }
    #single__mall-graphic-4{
        width: 400px;
    }
    #single__mall-graphic-5{
        top: 280px;
        width: 160px;
    }
}