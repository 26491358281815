body.body--single-mall{
    background-color: $white;
}
main.single-mall{
    .home-section{
        height: 80vh;
        .background-video-wrapper{
            height: 80vh;
        }
        margin-top: 80px;
        @media screen and(max-width: 767px){
            margin-top: 50px;
            height: 50vh;
            .background-video-wrapper{
                height: 50vh;
            }
        }
    }
    .map-section, .single-mall-map{
        min-height: calc(100vh - 113px);
        height: auto;
        @media screen and(max-width: 767px){
            min-height: calc(100vh - 100px);
        }
    }
}
