[class^=icon]{
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon--go-down-arrow{
    background-image: url("../../assets/images/arrow_down.svg");
    width: 38px;
    height: 22px;

    &.icon--go-down-arrow-dark{
        background-image: url("../../assets/images/arrow_down_dark.svg");
        width: 32px;
        height: 17px;
    }
}
.icon--arrow-left--light{
    background-image: url("../../assets/images/arrow_down.svg");
    width: 38px;
    height: 22px;
    transform: rotate(90deg);
}
.icon--arrow-right--light{
    background-image: url("../../assets/images/arrow_down.svg");
    width: 38px;
    height: 22px;
    transform: rotate(-90deg);
}
.icon--arrow-left{
    background-image: url("../../assets/images/arrow_right.svg");
    transform: rotate(180deg);
    width: 20px;
    height: 38px;
}
.icon--arrow-right{
    background-image: url("../../assets/images/arrow_right.svg");
    width: 20px;
    height: 38px;
}
.icon--close{
    width: 24px;
    height: 24px;
    background-image: url("../../assets/images/close.svg");
    background-repeat: no-repeat;
}