.multi-map-section {
  position: relative;
  min-height: calc(100vh - 63px);
  overflow: hidden;

  @media screen and(max-width: 991px) {
    background-color: $teal-primary;
    min-height: 80vh;
    padding-bottom: 160px;
  }
  @media screen and(max-width: 767px){
    min-height: 350px;
    padding-bottom: 100px;
  }
}
.multi-map-section--list-open {
  .multi-map {
    width: 65%;
  }
  .mall-info__wrapper {
    transform: translateX(100%);
  }
  .map-list__wrapper {
    transform: translateX(0);
  }
}
.multi-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  transition: width 1000ms cubic-bezier(0.51, 0.51, 0, 0.995) 350ms;

  @media screen and(max-width: 991px) {
    display: none;
  }
}
.mall-info__wrapper {
  position: absolute;
  width: 65%;
  height: 100%;
  z-index: 350;
  background-color: $white;
  transform: translateX(165%);
  transition: transform 1000ms cubic-bezier(0.51, 0.51, 0, 0.995) 350ms;
  color: $dark-primary;
  padding: 20px 6vw;

  &.mall-info__wrapper--open {
    transform: translateX(0);
  }
  @media screen and(max-width: 1439px) {
   padding: 20px 4vw;
  }
  @media screen and(max-width: 1199px) {
   padding: 10px 15px;
  }
  @media screen and(max-width: 991px) {
    width: 100%;
    transform: translateX(100%);
    padding: 10px 15%;
    z-index: 550;
  }
  @media screen and(max-width: 767px) {
    padding: 0px 15px;
  }
}
.mall-info__heading {
  font-size: 32px;
  color: $dark-primary;
  text-transform: uppercase;
  margin-bottom: 32px;

  @media screen and(max-height: 800px){
      font-size: 24px;
      margin-bottom: 18px;
  }
}
.mall-info__content-wrapper {
  margin: 12px 0;
  font-size: 16px;
  @media screen and(max-width: 1199px) {
    font-size: 14px;
  }
  @media screen and(max-height: 800px){
      font-size: 14px;
  }
}
.mall-info__content{
  ul{
    list-style: disc;
    list-style-position: inside;
  }
  ol{
    list-style: decimal;
    list-style-position: inside;
  }
}
.mall-info__button-wrapper {
  margin-top: 36px;
  a {
    margin-right: 20px;
    font-size: 16px;

    @media screen and(max-width: 1199px) {
      margin-right: 15px;
      font-size: 14px;
    }
    @media screen and(max-width: 767px) {
      margin-right: 8px;
      font-size: 12px;
      padding: 8px 10px;
    }
    @media screen and(max-height: 800px){
        font-size: 12px;
    }
  }
}
.mall-info__close {
  position: absolute;
  top: 30px;
  right: 2.75vw;
  z-index: 550;
  background-color: transparent;
  border: none;

  &:focus {
    outline-color: $teal-primary;
  }
  @media screen and(max-width: 767px) {
    top: 24px;
    right: 24px;
  }
}
.mall-info__loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  transform: translateX(0);
  transition: all 500ms cubic-bezier(0.51, 0.51, 0, 0.995);
  pointer-events: none;

  &.hidden-anim {
    opacity: 0;
    transform: translateX(30px);
  }

  .spinner {
    /* Spinner size and color */
    width: 40px;
    height: 40px;
    border-top-color: $dark-primary;
    border-left-color: $dark-primary;
    position: relative;
    /* Additional spinner styles */
    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
}
.mall-info__article-wrapper {
  transition: all 500ms cubic-bezier(0.51, 0.51, 0, 0.995);
  &.hidden-anim {
    opacity: 0;
    transform: translateX(30px);
  }
  padding-top: 20px;
  padding-bottom: 100px;
  article{
    padding: 12px;
    background-color: rgba($white, 0.85);
    position: relative;
    z-index: 500;
  }
  @media screen  and(max-width: 767px){
    padding: 24px 18px;
  }
}
.map-list__wrapper {
  position: absolute;
  height: 100%;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 35%;
  background-color: $teal-primary;
  z-index: 500;
  padding: 60px 40px 60px 60px;
  color: $dark-primary;
  h1 {
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 36px;
  }
  transition: transform 1000ms cubic-bezier(0.51, 0.51, 0, 0.995) 350ms;
  transform: translateX(100%);

  @media screen and(max-width: 1199px) {
    padding: 20px 10px 20px 30px;
    h1 {
      font-size: 24px;
      margin-bottom: 18px;
    }
  }
  @media screen and(max-width: 1439px) {
    padding: 30px 10px 20px 20px;
    h1 {
      font-size: 22px;
      margin-bottom: 14px;
    }
  }
  @media screen and(max-width: 991px) {
    width: 100%;
    transform: translateX(0);
    position: static;
    background-color: transparent;
    h1 {
      font-size: 32px;
    }
  }
  @media screen and(max-width: 767px) {
    padding: 26px 20px;
    h1 {
      font-size: 24px;
      margin-bottom: 8px;
    }
  }
}
.map-list__column {
  width: 50%;
  float: left;
  &:not(.last){
    padding-right: 15px;
  }
  h2 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media screen and(max-width: 1199px) {
    h2 {
      font-size: 13px;
    }
  }
  @media screen and(min-width: 768px) and (max-width: 991px) {
    h2 {
      font-size: 16px;
    }
  }
  @media screen and(max-width: 767px) {
    h2 {
      font-size: 12px;
    }
  }
}

ul.map-list__list {
  padding-top: 16px;
  font-size: 16px;
}
li.map-list__list-item {
  margin-bottom: 16px;
  a {
    cursor: pointer;
    color: $dark-primary;
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $dark-primary;
    }
    &.active {
      text-decoration: underline;
      font-weight: 600;
    }
    &.marker-hovered {
      text-decoration: underline;
    }
    @media screen and(max-width: 767px) {
      font-size: 14px;
    }
  }
}

.mall-info__graphics-wrapper{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 600px;
    img, div{
        position: absolute;
    }
    transition: all 500ms cubic-bezier(0.51, 0.51, 0, 0.995);
    &.hidden-anim {
      opacity: 0;
      transform: translateX(30px);
    }
    @media screen and(max-width: 767px){
      height: 200px;

      *:not(#mall-info__graphics-2){
        display: none;
      }
    }
}
#mall-info__graphics-1{
    width: 300px;
    height: 400px;
    padding-top: 20px;
    bottom: 180px;
    right: 7vw;
    @media screen and(min-width: 992px) and (max-width: 1199px){
        right: 5vw;
    }
    @media screen and(min-width: 768px) and (max-width: 991px){
        right: 3vw;
        bottom: 350px;
    }
}
#mall-info__graphics-4{
    width: 100px;
    height: 100px;
    bottom: 340px;
    right: 1vw;
    @media screen and(min-width: 992px) and (max-width: 1199px){
        right: 5vw;
    }
    @media screen and(min-width: 768px) and (max-width: 991px){
        right: 3vw;
        bottom: 350px;
    }
}
#mall-info__graphics-2{
    width: calc(100% + 40px);
    height: 460px;
    background-position-x: 50%;
    background-position-y: 0;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: -55px;
    left: 0;
    @media screen and(min-width: 768px) and (max-width: 991px){
        left: 0;
    }
    @media screen and(max-width: 767px){
      height: 200px;
    }
}
#mall-info__graphics-3{
    width: 120px;
    height: auto;
    bottom: 350px;
    left: 12vw;
    @media screen and(min-width: 992px) and (max-width: 1199px){
        left: 5vw;
    }
    @media screen and(min-width: 768px) and (max-width: 991px){
        left: auto;
        right: 25vw;
    }
}
#mall-info__graphics-5{
  width: 320px;
  height: 320px;
  position: absolute;
  top: -270px;
  left: 50px;
}
#mall-info__graphics-6{
  width: 40px;
  height: 40px;
  position: absolute;
  top: 60px;
  left: 90px;
}

@media screen and(max-width: 991px){
  #mall-info__graphics-5, #mall-info__graphics-6{
    display: none;
  }
}