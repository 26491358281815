.prizes-section{
    background-color: $white;
    position: relative;
    min-height: calc(100vh - 113px);

    @media screen and(max-width: 767px){
        padding-bottom: 120px;
        min-height: calc(100vh - 100px);
        display: block;
    }
    display: flex;
    align-items: center;

    padding-bottom: 260px;
}
.prizes-section__quote{
    font-size: 32px;
    color: $dark-primary;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 0.3px;
    line-height: 1.2;
    position: relative;
    z-index: 20;
    margin-bottom: 40px;
    text-align: center;

    &:before{
        content: '';
        background-image: url("../../assets/images/quote.svg");
        background-size: 150px 150px;
        display: block;
        width: 150px;
        height: 150px;
        position: absolute;
        top: -25px;
        left: 20px;
        z-index: -1;
    }

    @media screen and(max-width: 767px){
        font-size: 24px;
        &:before{
            left: 0px;
        }
    }
}
.prizes__slider-wrapper{
    position: relative;
}
.prizes__slider{
    position: relative;
    padding: 30px 100px;
    margin: 0 80px;
    &:before, &:after{
        display: inline-block;
        content: '';
        width: 60px;
        height: 120px;
        background-image: url("../../assets/images/laurel.svg");
        background-size: 60px 120px;
        position: absolute;
    }
    &:before{
        top: 30px;
        left: 0;
    }
    &:after{
        top: 30px;
        right: 0;
        transform: scale(-1, 1)
    }
}
.prizes__slider-item{
    padding: 0 10px;
    img{
        width: 120px;
        height: 120px;
        margin: 0 auto;
    }
}
.prizes__slider-arrows{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
}
.prizes__slider-arrow--left, .prizes__slider-arrow--right{
    position: absolute;
    top: 70px;
    pointer-events: auto;
    cursor: pointer;
}
.prizes__slider-arrow--left{
    left: 140px;
}
.prizes__slider-arrow--right{
    right: 140px;
}

@media screen and(max-width: 549px){
    .prizes__slider{
        position: relative;
        padding: 30px 70px;
        margin: 0;
    }
    .prizes__slider-arrow--left{
        left: 35px;
    }
    .prizes__slider-arrow--right{
        right: 35px;
    }
    
}