.home-section {
  position: relative;

  .background-video-wrapper{
    video{
        @media screen and(max-width: 767px){
            width: 100%
        }
    }
  }

  .hero-section__content{
    opacity: 0;
  }

  @media screen and(min-width: 768px) {
    &:not(.single-mall__home-section) {
      position: fixed;
      top: 0;
      width: 100%;

      &.home-section--absolute {
        position: absolute;
        top: 75vh;
        height: 100vh;
      }
    }
  }
}
.home-section__paragraph {
  font-size: 21px;
  @media screen and(max-width: 767px) {
    & {
      font-size: 16px;
    }
  }
}

.hero-section__content {
  .home-section__heading,
  .home-section__paragraph {
    text-shadow: 0px 0px 10px #000000;
  }
}

.home-placeholder {
  height: 100vh;
  padding-top: 75vh;
  color: $white;
  position: relative;
  pointer-events: none;
  padding-bottom: 100vh;

  .hero-section__content{
    pointer-events: none;
    opacity: 0;
  }

  @media screen and(max-width: 767px) {
    display: none;
  }
}
