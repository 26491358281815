.background-video-wrapper{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media screen and(max-width: 767px){
        height: calc(100vw - 100vw/2.27);
        @media screen and(orientation:landscape){
            height: 100vh;
        }
    }
}

.background-video-wrapper--slider{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 113px);
    @media screen and(max-width: 767px){
        height: calc(100vh - 100px);
    }
}

.background-video-wrapper, .background-video-wrapper--slider{
    video{
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
    }
}

