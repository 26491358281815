.not-found-section, .not-found-section .background-video-wrapper{
    height: calc(100vh - 37px);
    @media screen and(max-width: 767px){
        min-height: calc(100vh - 74px);
        height: auto;
    }
}
.not-found-wrapper{
    width: 100%;
    img{
        margin: 0 auto;
        width: 100%;
        height: auto;
        margin-top: 24px;
        max-width: 360px;
        @media screen and(max-width: 767px){
            margin-top: 60px;
            max-width: 260px;
        }
    }
    p{
        font-size: 18px;
        margin: 48px 0;
        text-align: center;
        @media screen and(max-width: 767px){
            font-size: 12px;
        }
    }
    margin-bottom: 32px;
}

.safari{
    .not-found-section{
        .button--bordered{
            &:hover{
                background-color: $white;
            }
        }
    }
}