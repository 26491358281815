.multimedia-section{
    position: relative;
    min-height: calc(100vh - 113px);
    @media screen and(max-width: 767px){
        padding-bottom: 200px;
        min-height: calc(100vh - 100px);
    }
}
.multimedia__slider-item{
    width: 100vw;
    position: relative;
    background-size: cover;
    background-position: 50%;

    min-height: calc(100vh - 113px);
    @media screen and(max-width: 767px){
        min-height: calc(100vh - 100px);
    }
}
.multimedia-slider__wrapper{
    position: relative;
}
.multimedia-slider__arrow--right{
    right: 0;
}
.multimedia-slider__dots{
    position: absolute;
    bottom: 40px;
    left: 75%;
    transform: translateX(-50%);
    @media screen and(max-width: 767px){
        left: 50%;
        bottom: -75px;
    }
}