.button--bordered{
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase;
    padding: 8px 32px;
    border-width: 3px;
    border-style: solid;
    border-color: $white;
    border-radius: 0;
    color: $white;
    font-weight: 600;
    overflow: hidden;
    position: relative;

    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    
    &:focus{
        outline-color: $teal-primary;
        color: $dark-primary;
        text-decoration: none;
    }
    &:after{
        content: "";
        position: absolute;
        height: 0%;
        left: 50%;
        top: 50%;
        width: 150%;
        z-index: -1;
        transition: all 0.75s ease 0s;
    }
    &:before{
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 0px;
        width: 100%;
        z-index: -1;
        content: '';
        color: #000 !important;
        background-color: $white;
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    }
    &:hover{
        color: $dark-primary;
        background-color: transparent;
        text-decoration: none;
        &:after{
            height: 450%;
        }
        &:before{
            bottom: 0%;
            top: auto;
            height: 100%;
        }
        .button__attention-seeker-anim{
            opacity: 0;
        }
    }
}
.button--bordered-dark{
    color: $dark-primary;
    border-color: $dark-primary;
    &:before{
        background-color: $dark-primary;
    }
    &:hover{
        color: $white;
    }
}
.button--bordered-narrow{
    padding: 10px 18px;
}

.button--pulse {
    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 2s;

    &:hover{
        animation: none;
    }
}
.button--pulse-delay{
    animation-delay: 2.2s;
}

@keyframes pulse {
    0%{
      transform: scale3d(1, 1, 1);
    }
  
    10% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  
    20%{
      transform: scale3d(1, 1, 1);
    }
  }
  
