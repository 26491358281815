.contact-section {
  position: relative;
  background-color: $white;
  background-image: url("../../assets/images/bg_kontakt.png");
  background-size: auto;
  background-position: 240px bottom;
  background-repeat: no-repeat;
  &#najem-section {
    background-position: 0px bottom;
  }
  @media screen and(max-width: 767px) {
    background-image: none;
  }
  @media screen and(min-width: 1200px){
    min-height: calc(100vh - 100px);
  }

  &.contact-section--single-mall{
    background-size: 100%;

    @media screen and(max-width: 991px){
      background-position-y: bottom;
    }
  }
}
.contact__container {
  padding-top: 40px;
  padding-bottom: 15px;
  @media screen and(max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 0;
  }
}
.contact__staff-slider {
  padding: 20px 20px 40px 20px;
  position: relative;
  &.contact__staff-slider--homepage{
    display: none;
  }
  @media screen and(max-width: 991px) {
    padding: 20px 0;
    &.contact__staff-slider--homepage{
        display: block;
    }
  }
}
.staff-slider {
  white-space: nowrap;
  overflow-x: auto;
  @media screen and(max-width: 767px) {
    padding-bottom: 35px;
  }
}
[class^="staff-slider__arrow"] {
  @media screen and(max-width: 767px) {
    display: none;
  }
}
.staff-slider__arrow--left {
  left: 5px;
}
.staff-slider__arrow--right {
  right: 5px;
}
.staff-slider__item {
  color: $dark-primary;
  font-size: 13px;
  padding: 0 10px;

  span {
    display: inline-block;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap;
    word-break: break-word;

    &.email{
      -ms-word-break: break-all;
      word-break: break-all;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }

  @media screen and(max-width: 767px) {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 30px;
    max-width: 220px;
    padding: 0 15px 0 0;
    &:first-child {
      padding-left: 15px;
      max-width: 235px;
    }
  }
}
.staff-slider__item-image {
  margin-bottom: 15px;
}
.staff-slider__item-text--primary {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  padding: 4px 0 10px;
}
.contact__address {
  padding: 15px 15px 15px 0;
  color: $dark-primary;
  font-size: 14px;
  letter-spacing: 0.2px;
  @media screen and(max-width: 767px) {
    padding: 15px;
  }
  @media screen and(min-width: 992px) and (max-width: 1199px){
      font-size: 13px;
  }
}
.contact__social {
  padding: 0;
  @media screen and(max-width: 767px) {
    padding: 15px;
  }
}
.address__company-name {
  font-size: 20px;
  color: $pink;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 600;
}

.phone-call__mobile {
  display: none;
  @media screen and(max-width: 767px) {
    display: inline-block;
    position: fixed;
    bottom: 12px;
    right: 15px;
    transform: translateY(100px) rotate(70deg);
    transition: transform 550ms cubic-bezier(0.79, -0.535, 0.255, 1.54);
    z-index: 1300;

    &.phone-call__mobile-visible {
      transform: translateY(0) rotate(0);
    }

    img {
      width: 56px;
      height: 56px;
    }
  }
}

.staff__item{
    width: 33%;
    float: left;
    margin: 0 0 30px;
    padding: 0 10px;
    font-size: 13px;
    @media screen and(max-width: 991px){
        width: 100%;
        &:not(.staff__item-contact){
            display: none;
        }
    }
    span {
        display: inline-block;
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap;
        word-break: break-word;
    
        &.email{
          -ms-word-break: break-all;
          word-break: break-all;
      
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;  
        }
      }
}

.contact__form-wrapper {
  background-color: $dark-primary;
  color: $white;
  padding: 20px 50px;
  @media screen and(max-width: 767px) {
    padding: 20px 15px;
    border-bottom: 4px $white solid;
  }
  @media screen and(min-width: 768px) and (max-width: 991px) {
    padding: 20px;
  }
  @media screen and(min-width: 992px) and (max-width: 1199px) {
    padding: 20px 30px;
  }
}
.contact__form-info {
  margin-bottom: 15px;
  h1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
}

.contact-form {
  ::selection {
    background: $pink;
  }
  .form-group {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 15px;
    }

    input[type="text"].form-control {
      background-color: transparent;
      color: $white;
      font-size: 14px;
      border-radius: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      box-shadow: none;
      &:focus {
        outline: none;
        border-color: $white;
        &::-webkit-input-placeholder {
          color: $white;
        }
      }

      &::-webkit-input-placeholder {
        color: #92989d;
      }
    }

    textarea {
      width: 100%;
      font-size: 14px;
      padding: 8px 13px;
      background-color: transparent;
      border: 1px $white solid;
      outline: none;
      resize: none;
      &:focus {
        border-color: $white;
        &::-webkit-input-placeholder {
          color: $white;
        }
      }
      &::-webkit-input-placeholder {
        color: #92989d;
      }
    }

    select,
    .form-control,
    textarea {
      &.error {
        margin-bottom: 0 !important;
        border-color: $red;
        &:focus {
          border-color: $red !important;
        }
      }
      & + label.error {
        font-weight: 500;
        font-size: 12px;
        color: $red;
      }
    }

    .bootstrap-select.open {
      label.error {
        display: none;
      }
    }
    #checkbox-error{
      padding-left: 5px;
      color: $red;
    }
  }

  .captcha{
    img,
    div{
      display: inline-block;
      float: left;
    }
    div{
      padding-left: 12px;
      p{
        font-size: 11px;
        margin-bottom: 5px;
      }
      button{
        background: none;
        text-decoration: underline;
        appearance: none;
        border: none;
        font-size: 11px;
        padding: 0;
        cursor: pointer;

        img{
          height: 11px;
          margin-top: 2px;
          margin-right: 4px;
        }
      }
    }
    p{

    }
  }
}

.btn-group.bootstrap-select {
  width: 100% !important;
  transition: all 150ms ease-out;
  .dropdown-toggle {
    transition: all 150ms ease-out;
    background-color: transparent;
    color: $white;
    font-size: 14px;
    border: none;
    border-radius: 0;
    border-bottom: 1px $white solid;
    padding-top: 8px;
    padding-bottom: 8px;
    &:focus {
      outline: none !important;
      background-color: transparent;
      box-shadow: none;
      color: $white;
      border-bottom-color: $white;
    }
    &:hover {
      background-color: transparent;
      color: $white;
      border-color: $white;
    }
    &::-webkit-input-placeholder {
      color: #dedede;
    }

    .bs-caret {
      .caret {
        background-image: url("../../assets/images/arrow_down.svg");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        border: none !important;
        position: relative;
        top: 1px;
        transition: all 150ms ease-out;
      }
    }
  }
  &.open {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.35);
    .dropdown-toggle {
      box-shadow: none;
      background-color: $white;
      color: $dark-primary;
      padding-top: 16px;
      padding-bottom: 16px;
      z-index: 1100;
      border: none;
      .bs-caret {
        .caret {
          transform: rotate(180deg);
          top: -3px;
          background-image: url("../../assets/images/arrow_down_dark.svg");
        }
      }
    }
  }
  .dropdown-menu {
    transition: all 150ms ease-out;
    border-radius: 0;
    border-color: $white;
    margin-top: 0;
    background-color: $white;
    font-size: 16px;
    box-shadow: none;
    padding: 0;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.35);
    height: 0;

    &.open {
      border: none;
    }

    ul.dropdown-menu.inner {
      li {
        a {
          color: $dark-primary;
          padding: 12px;

          &:focus {
            background-color: $pink;
            color: $white;
            outline: none;
          }
          &:hover {
            background-color: $teal-light;
            color: $dark-primary !important;
          }
        }

        &.selected {
          background-color: $pink;
          a {
            color: $white;
          }
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.message-textarea{
  margin-top:15px;
}
.rodo-box{
  color:$white;
  overflow:auto;
  height:100px;
  background-color:transparent;
  border-radius:0;
  border:1px solid $white;
  padding:5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 400;
  li{
    padding-bottom: 5px;
  }
  label{
    font-weight: 400;
  }
}
.rodo-error{
  border:1px solid $red;
}
.rodo-valid{
  border:1px solid white;
}
.rodo_consent--error{
  color:$red;
  display:none;
}
.rodo-box--error{
  border-color:$red;
}

.contact__submit-button {
  display: block;
  width: 100%;
  text-align: center;
  background-color: transparent;
  border-radius: 0;
  color: $white;
  padding: 14px 0;
  font-size: 14px;
  text-transform: uppercase;
  border: 2px $white solid;
  transition: all 250ms linear;
  &.contact__submit-button--error {
    border-color: red;
    background-color: red;
    &:hover {
      background-color: red;
      color: $white;
    }
  }
  &.contact__submit-button--success {
    border-color: $green;
    background-color: $green;
    &:hover {
      background-color: $green;
      color: $white;
    }
  }
  &:hover,
  &:focus,
  &[data-loading] {
    background-color: $white;
    outline: none;
    color: $dark-primary;
    .ladda-spinner {
      .spinner {
        border-top-color: $dark-primary;
        border-left-color: $dark-primary;
      }
    }
  }
  .ladda-spinner {
    top: 7.5px;
    color: $dark-primary;
    [role="progressbar"] {
      display: none;
    }
    .spinner {
      /* Spinner size and color */
      width: 30px;
      height: 30px;
      border-top-color: $white;
      border-left-color: $white;
      position: relative;
      left: -15px;
      /* Additional spinner styles */
      animation: spinner 400ms linear infinite;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.btn--facebook, .btn--linked-in{
  img{
    width: 100px;
    height: auto;
  }
}
