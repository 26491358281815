html, body{
    font-family: Arial, sans-serif;
}

h1{
    font-size: 60px;
    font-weight: 600;
}
i{
    font-style: italic;
}