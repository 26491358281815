.investment-description-section{
    margin-top: -90px;
    padding-bottom: 200px;
    position: relative;
    @media screen and(max-width: 767px){
        margin-top: -30px;
        padding-bottom: 135px;
    }
}
.investment-description__container{
    background-color: $white;
    @media screen and(max-width: 767px){
        margin: 0 15px;
    }
}
.investment-description__column{
    color: $dark-primary;
    text-align: center;
    padding-top: 45px;
    @media screen and(max-width: 767px){
        padding-top: 15px;
    }
    h1{
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 48px;
        @media screen and(max-width: 767px){
            font-size: 22px;
            margin-bottom: 24px;
        }
    }
    p{
        font-size: 18px;
        @media screen and(max-width: 767px){
            font-size: 14px;
        }
    }
    .investment-description__opening-hours{
        border: 1px $dark-primary solid;
        margin: 50px 0;
        padding: 30px 0;
        font-size: 16px;
        h1{
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 16px;
            text-transform: uppercase;
        }
        *:last-child{
            margin-bottom: 0;
        }
    }
}