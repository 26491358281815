@import '../../node_modules/reset-css/_reset.scss';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css';
@import '../../node_modules/ladda/dist/ladda-themeless.min.css';

@import 'flex.scss';
@import 'mixins.scss';
@import 'variables.scss';
@import 'typography.scss';
@import 'icons.scss';
@import 'hero-section.scss';
@import 'buttons.scss';

@import 'main.scss';
@import 'single-mall.scss';

@import 'menu.scss';
@import 'video.scss';

@import 'home.scss';
@import 'about.scss';
@import 'strategy.scss';
@import 'key-info.scss';
@import 'contact.scss';
@import 'footer.scss';
@import 'multi-map.scss';
@import 'prizes.scss';
@import 'history.scss';

@import 'investment-description.scss';
@import 'single-mall-key-info.scss';
@import 'map.scss';
@import 'multimedia.scss';

@import 'media.scss';

@import '404.scss';
